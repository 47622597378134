import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';

const ServicesPage = () => (
	<ThemeProvider theme={{ mode: 'light' }}>
		<Layout>
			<SEO title="Services" />
			<div>
				<h1>Services</h1>
				<p>These are my services</p>
				<ul>
					<li>Service 1</li>
					<li>Service 2</li>
					<li>Service 3</li>
				</ul>
			</div>
		</Layout>
	</ThemeProvider>
);

export default ServicesPage;
